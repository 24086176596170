@import url(https://use.typekit.net/yeb4kiz.css);
@charset "UTF-8";
@font-face {
  font-family: "Harbour";
  font-style: normal;
  font-weight: 400;
  src: url("/static/media/Harbour W00 Medium.e4c7cdba.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans"), local("OpenSans"), url("/static/media/Harbour W00 Medium.e4c7cdba.eot") format("embedded-opentype"), url("/static/media/Harbour W00 Medium.e2c08dfb.woff2") format("woff2"), url("/static/media/Harbour W00 Medium.aebc99cf.woff") format("woff"), url("/static/media/Harbour W00 Medium.cac5cba0.ttf") format("truetype"), url("/static/media/Harbour W00 Medium.b2c3a63e.svg") format("svg");
  /* Legacy iOS */ }

body {
  font-family: roc-grotesk, "Arial", "Helvetica", sans-serif;
  font-size: 62.5%;
  background: white;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 82px; }

body.loading-component-body {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 0; }

.loading {
  display: block;
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/loading_background.90a628de.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100; }
  .loading .title {
    display: block;
    width: 200px;
    height: 90px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
    .loading .title .subtitle {
      margin-top: 30px; }
  .loading .opacity_layer {
    height: 100%;
    background-color: #db1d51;
    mix-blend-mode: darken;
    /*transition: height 1.6s cubic-bezier(0.86, 0, 0.07, 1);
    will-change: height;*/ }
  .loading__progress {
    position: absolute;
    top: 100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 18px;
    line-height: 1.22em;
    text-align: center;
    color: white; }
  .loading--exit .title {
    opacity: 1; }
  .loading--exit-active .title {
    -webkit-transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0; }

.header {
  width: 100%;
  height: 64px;
  background-color: white;
  box-shadow: 0 4px 62px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  z-index: 99;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1); }
  @media (min-width: 1024px) {
    .header {
      height: 82px; } }
  .header .header_content {
    width: 88.8888888888%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .header .header_content .menu_logo {
      font-size: 0; }
      .header .header_content .menu_logo img {
        height: 24px; }
        @media (min-width: 1024px) {
          .header .header_content .menu_logo img {
            height: auto; } }
    .header .header_content ul {
      margin: 0;
      padding: 0;
      margin-left: 130px;
      font-size: 15px; }
      @media only screen and (max-width: 768px) {
        .header .header_content ul {
          margin-left: 90px; } }
      @media only screen and (max-width: 652px) {
        .header .header_content ul {
          margin-left: 70px; } }
      @media only screen and (max-width: 682px) {
        .header .header_content ul {
          display: none; } }
      .header .header_content ul a {
        color: black;
        text-decoration: none;
        cursor: pointer;
        border-radius: 56px;
        padding: 7px 15px 6px 16px;
        margin: -7px -15px -6px -16px;
        -webkit-transition: background-color 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1);
        transition: background-color 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1); }
      .header .header_content ul li {
        display: inline-block;
        background-color: transparent;
        padding: 7px 15px 6px 16px;
        border-radius: 56px; }
        .header .header_content ul li a:hover:not(.active) {
          background-color: #db1d51;
          color: white; }
      .header .header_content ul li:not(:last-child) {
        margin-right: 60px; }
        @media only screen and (max-width: 863px) {
          .header .header_content ul li:not(:last-child) {
            margin-right: 40px; } }
        @media only screen and (max-width: 815px) {
          .header .header_content ul li:not(:last-child) {
            margin-right: 35px; } }
        @media only screen and (max-width: 803px) {
          .header .header_content ul li:not(:last-child) {
            margin-right: 30px; } }
        @media only screen and (max-width: 790px) {
          .header .header_content ul li:not(:last-child) {
            margin-right: 25px; } }
        @media only screen and (max-width: 780px) {
          .header .header_content ul li:not(:last-child) {
            margin-right: 10px; } }
      .header .header_content ul .active {
        background-color: #db1d51;
        color: white;
        border-radius: 56px;
        padding: 7px 15px 6px 16px;
        margin: -7px -15px -6px -16px; }
    .header .header_content .appstore_badge {
      background: #000;
      padding: 2px 12px;
      border-radius: 56px;
      display: flex; }
      .header .header_content .appstore_badge img {
        height: 28px; }
        @media (min-width: 1024px) {
          .header .header_content .appstore_badge img {
            height: auto; } }
  .header--hidden {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  .header--appear, .header--enter {
    -webkit-transition: none;
    transition: none;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
    .header--appear-active, .header--enter-active {
      -webkit-transition: -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transform: translateY(0);
              transform: translateY(0); }
  .header--exit {
    -webkit-transition: none;
    transition: none;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
    .header--exit-active {
      -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }

footer {
  background-color: #db1d51;
  width: 100%;
  bottom: 0;
  padding: 32px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; }
  @media (min-width: 1024px) {
    footer {
      padding: 50px 80px;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between; } }
  footer span,
  footer a,
  footer p {
    font-size: 14px;
    color: white;
    font-weight: normal; }
    @media (min-width: 1024px) {
      footer span,
      footer a,
      footer p {
        font-size: 18px; } }
  footer p {
    margin: 0;
    color: white; }
  footer .footer__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; }
    footer .footer__logo img {
      height: 16px;
      margin-bottom: 4rem; }
      @media (min-width: 1024px) {
        footer .footer__logo img {
          height: 24px; } }
  footer .footer__actions {
    margin-top: 4rem; }

.cookies-banner {
  position: fixed;
  bottom: 0;
  background: #db1d51;
  padding: 16px 16px;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  opacity: 1;
  -webkit-transition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out; }
  @media (min-width: 1024px) {
    .cookies-banner {
      padding: 16px 37px; } }
  .cookies-banner--hidden {
    opacity: 0; }
  .cookies-banner--unmounted {
    display: none; }
  .cookies-banner span {
    color: white;
    font-weight: 100;
    font-size: 12px;
    flex-grow: 1; }
    @media (min-width: 1024px) {
      .cookies-banner span {
        font-size: 15px; } }
  .cookies-banner a {
    font-size: 11px;
    margin-left: 8px;
    border: 1px solid white;
    color: white;
    padding: 6px 12px;
    background: #db1d51;
    -webkit-transition: all 0.48s ease-out;
    transition: all 0.48s ease-out;
    border-radius: 32px;
    text-decoration: none;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    @media (min-width: 1024px) {
      .cookies-banner a {
        font-size: 13px;
        padding: 8px 16px; } }
    .cookies-banner a:hover {
      background: white;
      color: #db1d51; }
    .cookies-banner a:last-of-type {
      background: white;
      color: #db1d51; }

.phone {
  height: 72vh;
  position: relative; }
  .phone canvas {
    height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .phone__inner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .phone .tap_to_start {
    width: 64%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1); }
  .phone .tap_invite {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(12.7888112vh);
    height: calc(12.7888112vh);
    position: absolute;
    bottom: 15.3846154%;
    z-index: 3;
    -webkit-transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer; }
    .phone .tap_invite .circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #db1d51;
      position: absolute;
      opacity: 0;
      -webkit-animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
              animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
      z-index: 2; }
    .phone .tap_invite .one {
      -webkit-animation-delay: -3s;
              animation-delay: -3s; }
    .phone .tap_invite .two {
      -webkit-animation-delay: -2s;
              animation-delay: -2s; }
    .phone .tap_invite .three {
      -webkit-animation-delay: -1s;
              animation-delay: -1s;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
    .phone .tap_invite .white_dot {
      z-index: 4;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

@keyframes scaleIn {
  from {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(2, 2);
            transform: scale(2, 2);
    opacity: 0; } }
  .phone .enjoy {
    position: absolute;
    top: 50%;
    -webkit-transform: translsateY(-50%);
            transform: translsateY(-50%);
    opacity: 0;
    -webkit-transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1; }
  .phone .character {
    width: 23.6643357vh;
    position: absolute;
    bottom: 3.6363636%;
    opacity: 0;
    -webkit-transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1; }

.features {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 150px auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative; }
  @media (min-width: 1024px) {
    .features {
      flex-direction: row;
      text-align: left;
      text-align: initial;
      margin-bottom: 112px;
      margin-top: 19.387755102vh; } }
  .features .technical_features {
    width: 100%;
    margin-bottom: 32px; }
    @media (min-width: 1024px) {
      .features .technical_features {
        width: 40%;
        margin-bottom: 0; } }
    .features .technical_features .title {
      margin-bottom: 25px; }
    .features .technical_features p {
      font-size: 16px;
      margin: 0 auto; }
      @media (min-width: 1024px) {
        .features .technical_features p {
          font-size: 24px;
          line-height: 29px;
          margin: 0 auto 80px; } }
      .features .technical_features p .p-mobile {
        display: inline-block; }
        @media (min-width: 1024px) {
          .features .technical_features p .p-mobile {
            display: none; } }
      .features .technical_features p .p-desktop {
        display: none; }
        @media (min-width: 1024px) {
          .features .technical_features p .p-desktop {
            display: block; } }
    @media (min-width: 1024px) {
      .features .technical_features .slider ul.slider-content {
        margin-left: 0;
        padding-left: 0; } }
    .features .technical_features .slider ul.slider-content li {
      vertical-align: middle;
      -webkit-transform: translateX(calc(-100% * var(--slider-constant--1)));
              transform: translateX(calc(-100% * var(--slider-constant--1))); }
      @media (min-width: 1024px) {
        .features .technical_features .slider ul.slider-content li {
          vertical-align: bottom; } }
      .features .technical_features .slider ul.slider-content li::before {
        display: none; }
      @media (min-width: 1024px) {
        .features .technical_features .slider ul.slider-content li p {
          text-align: left; } }
    .features .technical_features ul.slider-controller {
      margin-bottom: 0;
      margin-top: 25px;
      position: absolute;
      top: 100%;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media (min-width: 1024px) {
        .features .technical_features ul.slider-controller {
          margin-top: 80px;
          margin-left: 0;
          -webkit-transform: none;
                  transform: none;
          position: static; } }
  .features .slide_gallery {
    width: 100%;
    height: auto; }
    @media (min-width: 1024px) {
      .features .slide_gallery {
        width: 558px;
        height: 477px; } }
    .features .slide_gallery .stack {
      width: 100%;
      height: 378px;
      position: relative;
      flex: 1 1 auto;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent; }
      @media (min-width: 1024px) {
        .features .slide_gallery .stack {
          height: 477px; } }
    .features .slide_gallery .card {
      flex: 1 1 auto;
      width: 100%;
      height: 378px;
      position: absolute;
      opacity: 1;
      -webkit-transition: 0.7s cubic-bezier(0.23, 1, 0.32, 1);
      transition: 0.7s cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transition-property: opacity, -webkit-transform;
      transition-property: opacity, -webkit-transform;
      transition-property: opacity, transform;
      transition-property: opacity, transform, -webkit-transform;
      -webkit-transform-origin: right;
              transform-origin: right; }
      @media (min-width: 1024px) {
        .features .slide_gallery .card {
          height: 477px; } }
    .features .slide_gallery .card1 {
      background-image: url(/static/media/features_img1.c3265411.jpg);
      background-size: cover; }
    .features .slide_gallery .card2 {
      background-image: url(/static/media/features_img2.3a50cde9.jpg);
      background-size: cover; }
    .features .slide_gallery .card3 {
      background-image: url(/static/media/features_img3.de1c5de8.jpg);
      background-size: cover; }
    .features .slide_gallery .first {
      z-index: 3;
      -webkit-transform: translateX(0) scale(1);
              transform: translateX(0) scale(1); }
    .features .slide_gallery .second {
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.3);
      background-blend-mode: darken;
      -webkit-transform: translateX(4vw) scale(0.9);
              transform: translateX(4vw) scale(0.9); }
      @media (min-width: 1024px) {
        .features .slide_gallery .second {
          -webkit-transform: translateX(1.8vw) scale(0.9);
                  transform: translateX(1.8vw) scale(0.9); } }
    .features .slide_gallery .third {
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.3);
      background-blend-mode: darken;
      -webkit-transform: translateX(8vw) scale(0.8);
              transform: translateX(8vw) scale(0.8); }
      @media (min-width: 1024px) {
        .features .slide_gallery .third {
          -webkit-transform: translateX(3.6vw) scale(0.8);
                  transform: translateX(3.6vw) scale(0.8); } }

.slider {
  display: flex;
  flex-direction: column; }
  .slider ul {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    padding: 0 20px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box; }
    .slider ul.slider-controller {
      width: auto;
      margin-top: 82px;
      margin-bottom: 112px;
      padding: 0; }
      .slider ul.slider-controller li {
        width: auto;
        font-size: 0; }
        .slider ul.slider-controller li a {
          display: inline-block;
          padding: 4px;
          -webkit-transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
          transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
          opacity: 0.2;
          cursor: pointer; }
          .slider ul.slider-controller li a::before {
            content: "";
            display: inline-block;
            width: 31px;
            height: 2px;
            background: black; }
          .slider ul.slider-controller li a.active {
            opacity: 1; }
    .slider ul.slider-content li {
      opacity: 0;
      -webkit-transform: translateX(calc(-100% * var(--slider-constant)));
              transform: translateX(calc(-100% * var(--slider-constant)));
      -webkit-transition: opacity 0.6s, -webkit-transform 0.8s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      transition: opacity 0.6s, -webkit-transform 0.8s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 0.8s 0.4s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s;
      transition: transform 0.8s 0.4s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s, -webkit-transform 0.8s 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
      .slider ul.slider-content li.active {
        -webkit-transition: opacity 0.6s 0.4s;
        transition: opacity 0.6s 0.4s;
        opacity: 1; }
      .slider ul.slider-content li::before {
        content: "“";
        margin-bottom: -19px;
        font-size: 78px;
        line-height: 112px;
        text-align: center;
        text-transform: uppercase;
        color: #db1d51; }
    .slider ul li {
      display: inline-block;
      width: 100%;
      white-space: normal;
      color: black;
      text-align: center;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      vertical-align: bottom; }
      @media only screen and (min-width: 768px) {
        .slider ul li {
          vertical-align: middle; } }
      .slider ul li p {
        margin: 0;
        font-size: 30px;
        line-height: 1.2em; }
  .slider__author {
    margin-top: 40px;
    font-size: 17px;
    line-height: 1.2em;
    text-align: center;
    color: #db1d51; }
  .slider__badge {
    margin: 0;
    width: 198px;
    height: 61px;
    background-color: #004bd3;
    border-radius: 63.5px;
    background-image: url(/static/media/6D_badge.b14d7a38.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: none; }
    @media (min-width: 1024px) {
      .slider__badge {
        display: block; } }

hr {
  border-top: 0.5px solid #000000;
  position: absolute;
  left: 10%;
  right: 10%; }
  @media only screen and (max-width: 414px) {
    hr {
      right: 5%;
      left: 5%; } }

.credits {
  margin-left: 20px;
  font-size: 18px;
  margin-bottom: 64px; }
  @media (min-width: 1024px) {
    .credits {
      margin-left: 5.5555555556%; } }
  .credits__title {
    font-size: 20px;
    line-height: 101.13%;
    font-weight: 500;
    margin-bottom: 40px; }
    @media (min-width: 1024px) {
      .credits__title {
        font-size: 24px;
        margin-bottom: 64px; } }
  .credits__details {
    display: grid;
    grid-row-gap: 32px; }
    @media (min-width: 1024px) {
      .credits__details {
        grid-template-columns: 33% 33% 33%; } }
    .credits__details span {
      font-size: 16px; }
      @media (min-width: 1024px) {
        .credits__details span {
          font-size: 18px; } }
      .credits__details span p {
        margin: 0; }
      .credits__details span a {
        display: inline;
        color: black;
        text-decoration: none;
        font-size: 16px;
        font-style: normal; }
        @media (min-width: 1024px) {
          .credits__details span a {
            font-size: 18px; } }
        .credits__details span a:hover {
          color: #db1d51;
          border-bottom: 1px solid #db1d51; }
        .credits__details span a.interaction-disabled:hover {
          color: black;
          border-bottom: none; }
    .credits__details__title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px !important; }
      @media (min-width: 1024px) {
        .credits__details__title {
          font-size: 18px;
          margin-bottom: 16px !important; } }

.partnership {
  position: relative;
  top: 70px;
  margin-left: 10%;
  margin-right: 10%; }
  @media only screen and (max-width: 500px) {
    .partnership {
      margin-left: 20px;
      margin-right: 20px; } }
  .partnership p {
    font-size: 24px;
    line-height: 101.13%;
    font-weight: 600;
    margin-bottom: 50px; }
  .partnership .scrollable {
    overflow: auto; }
    .partnership .scrollable .logos {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      overflow: auto; }
      @media only screen and (max-width: 500px) {
        .partnership .scrollable .logos {
          width: 200%; } }
      .partnership .scrollable .logos a:not(:last-child) {
        margin-right: 94px; }
        @media only screen and (max-width: 414px) {
          .partnership .scrollable .logos a:not(:last-child) {
            margin-right: 52px; } }
        @media only screen and (max-width: 360px) {
          .partnership .scrollable .logos a:not(:last-child) {
            margin-right: 40px; } }
        @media only screen and (max-width: 320px) {
          .partnership .scrollable .logos a:not(:last-child) {
            margin-right: 31px; } }
      .partnership .scrollable .logos a:hover {
        cursor: pointer; }
  .partnership .scrollable::-webkit-scrollbar {
    display: none; }
  .partnership .logos::-webkit-scrollbar {
    display: none; }

.download {
  padding: 40px 20px 0;
  background: black;
  margin-bottom: 64px; }
  @media (min-width: 1024px) {
    .download {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 294px 0 188px;
      margin-bottom: 72px; } }
  .download__claim {
    max-width: 78.4%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 1024px) {
      .download__claim {
        max-width: none;
        margin-top: 120px;
        margin-bottom: 60px; } }
    .download__claim .appstore_badge {
      width: 36vw; }
    .download__claim img {
      max-width: 100%; }
      .download__claim img.text {
        margin: 16px 0 32px; }
        @media (min-width: 1024px) {
          .download__claim img.text {
            margin-top: 24px;
            margin-bottom: 96px; } }
  .download__device {
    margin: 72px 20px 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #c4c4c4;
    background-image: url(/static/media/device_bg_1.7ae8100c.png), -webkit-gradient(linear, left top, right top, from(RGB(219, 29, 81)), to(RGB(219, 29, 81)));
    background-image: url(/static/media/device_bg_1.7ae8100c.png), -webkit-linear-gradient(left, RGB(219, 29, 81), RGB(219, 29, 81));
    background-image: url(/static/media/device_bg_1.7ae8100c.png), linear-gradient(to right, RGB(219, 29, 81), RGB(219, 29, 81));
    background-blend-mode: darken;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative; }
    @media (min-width: 1024px) {
      .download__device {
        margin: 56px 0 0; } }
    .download__device__title {
      margin-top: 24px;
      margin-bottom: 96px; }
      @media (min-width: 1024px) {
        .download__device__title {
          margin: 28px 110px 204px; } }
    .download__device__bosch {
      width: 144px;
      margin: 0 auto; }
    .download__device__square {
      width: 96px;
      height: 96px;
      border-radius: 20px;
      -webkit-transform: translateX(-20px);
              transform: translateX(-20px);
      margin-bottom: 16px; }
      @media (min-width: 1024px) {
        .download__device__square {
          width: 134px;
          height: 134px;
          position: absolute;
          left: 0;
          bottom: 60px;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%); } }
    .download__device__bosch-character {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 52%;
      -webkit-transform: translate(40px, 50%);
              transform: translate(40px, 50%); }
      @media (min-width: 1024px) {
        .download__device__bosch-character {
          width: auto;
          -webkit-transform: translate(50%, 50%);
                  transform: translate(50%, 50%); } }

.about {
  width: 100%;
  padding-bottom: 112px;
  margin-top: 291px; }
  @media only screen and (max-width: 768px) {
    .about {
      padding-bottom: 76px;
      margin-top: 72px; } }
  .about .about_text {
    height: 20%;
    margin-left: 10%;
    margin-top: 100px;
    margin-bottom: 72px; }
    @media only screen and (max-width: 1000px) {
      .about .about_text {
        text-align: center;
        margin-top: 72px;
        margin-left: auto; } }
    @media only screen and (max-width: 768px) {
      .about .about_text {
        height: auto;
        margin-bottom: 48px; } }
    @media only screen and (max-width: 375px) {
      .about .about_text {
        margin-left: 10px;
        margin-right: 10px; } }
    .about .about_text .title {
      margin-bottom: 25px; }
    .about .about_text .title2 {
      margin: 0;
      margin-bottom: 25px; }
      @media only screen and (max-width: 414px) {
        .about .about_text .title2 {
          margin-right: 8px;
          margin-left: 8px; } }
      @media only screen and (max-width: 320px) {
        .about .about_text .title2 {
          font-size: 35px; } }
    .about .about_text p {
      margin: 0;
      max-width: 800px; }
      @media only screen and (max-width: 375px) {
        .about .about_text p {
          font-size: 16px; } }
    .about .about_text .p-mobile {
      display: none; }
    @media only screen and (max-width: 1000px) {
      .about .about_text .p-mobile {
        display: inline-block; }
      .about .about_text .p-desktop {
        display: none; } }
    .about .about_text .apostrophe {
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .about .pic_container {
    width: 100%;
    margin: 0 auto;
    padding: 0 10%;
    box-sizing: border-box; }
    @media only screen and (max-width: 768px) {
      .about .pic_container {
        padding: 0; } }
    .about .pic_container img {
      width: 100%; }

.carousel_section {
  width: 100%;
  height: auto;
  z-index: 1; }
  @media only screen and (max-width: 1024px) {
    .carousel_section {
      height: 600px; } }
  @media only screen and (max-width: 768px) {
    .carousel_section {
      text-align: center; } }
  @media only screen and (max-width: 414px) {
    .carousel_section {
      height: 350px; } }
  .carousel_section .title {
    position: relative;
    left: 10%; }
    @media only screen and (max-width: 768px) {
      .carousel_section .title {
        left: 0; } }
  .carousel_section .carousel_container {
    width: 80%;
    position: relative;
    margin: 20px auto; }
    @media only screen and (max-width: 768px) {
      .carousel_section .carousel_container {
        width: 100%; } }
    .carousel_section .carousel_container .slick-slider {
      width: 100%;
      margin: 25px auto 0; }
      .carousel_section .carousel_container .slick-slider .slick-track {
        font-size: 0; }
      .carousel_section .carousel_container .slick-slider .slide {
        width: 100%;
        height: 32.53373313vh;
        position: relative;
        outline: none; }
        @media (min-width: 1024px) {
          .carousel_section .carousel_container .slick-slider .slide {
            height: 30.612244898vh; } }
        .carousel_section .carousel_container .slick-slider .slide__filter {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: black;
          opacity: 0.2;
          z-index: 2; }
        .carousel_section .carousel_container .slick-slider .slide img {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1; }
          .carousel_section .carousel_container .slick-slider .slide img.slide__detail {
            z-index: 3;
            height: 128%;
            width: auto;
            object-fit: unset; }
            @media (min-width: 1024px) {
              .carousel_section .carousel_container .slick-slider .slide img.slide__detail {
                height: 196%; } }
            .carousel_section .carousel_container .slick-slider .slide img.slide__detail--1 {
              left: auto;
              -webkit-transform: translate3d(12%, -16%, 0);
                      transform: translate3d(12%, -16%, 0); }
            .carousel_section .carousel_container .slick-slider .slide img.slide__detail--2 {
              top: 50%;
              bottom: auto;
              left: auto;
              -webkit-transform: translate3d(-12%, -50%, 0);
                      transform: translate3d(-12%, -50%, 0); }
            .carousel_section .carousel_container .slick-slider .slide img.slide__detail--3 {
              top: 50%;
              bottom: auto;
              right: auto;
              left: 50%;
              -webkit-transform: translate3d(-62%, -50%, 0);
                      transform: translate3d(-62%, -50%, 0); }
            .carousel_section .carousel_container .slick-slider .slide img.slide__detail--4 {
              top: 50%;
              bottom: auto;
              right: auto;
              left: 50%;
              -webkit-transform: translate3d(-50%, -50%, 0);
                      transform: translate3d(-50%, -50%, 0); }
            .carousel_section .carousel_container .slick-slider .slide img.slide__detail--5 {
              top: 50%;
              bottom: auto;
              right: auto;
              left: 50%;
              -webkit-transform: translate3d(-70%, -50%, 0);
                      transform: translate3d(-70%, -50%, 0); }
    .carousel_section .carousel_container .slick-dots {
      position: relative;
      top: 108%;
      height: 30px;
      z-index: 2;
      display: block;
      width: 100%;
      padding: 0;
      margin: 16px 0 0;
      text-align: center; }
      @media only screen and (max-width: 830px) {
        .carousel_section .carousel_container .slick-dots {
          bottom: -100px; } }
      @media only screen and (max-width: 430px) {
        .carousel_section .carousel_container .slick-dots {
          bottom: -100px; } }
    .carousel_section .carousel_container .slick-dots li {
      position: relative;
      display: inline-block;
      width: 31px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
    .carousel_section .carousel_container .slick-dots li button {
      padding: 4px; }
    .carousel_section .carousel_container .slick-dots li button:before {
      content: "";
      display: inline-block;
      width: 31px;
      height: 2px;
      background: black;
      opacity: 0.25; }
    .carousel_section .carousel_container .slick-dots li.slick-active button:before {
      opacity: 1; }
    .carousel_section .carousel_container .carousel_char_1 {
      position: absolute;
      width: 60%;
      height: 90%;
      left: 5%;
      bottom: -16%;
      max-height: 300px;
      background-image: url(/static/media/character_1.63328dd1.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 1;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 768px) {
        .carousel_section .carousel_container .carousel_char_1 {
          left: 8%;
          max-height: 160px; } }
    .carousel_section .carousel_container .carousel_char_2 {
      position: absolute;
      width: 70%;
      height: 63.7837837838%;
      left: 5%;
      bottom: -16%;
      max-height: 236px;
      background-image: url(/static/media/character_2.6652e8e8.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 768px) {
        .carousel_section .carousel_container .carousel_char_2 {
          left: 10%;
          max-height: 118px; } }
    .carousel_section .carousel_container .carousel_char_3 {
      position: absolute;
      width: 70%;
      height: 40.54054054%;
      left: 5%;
      bottom: -16%;
      max-height: 118px;
      background-image: url(/static/media/character_3.be2a03de.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 768px) {
        .carousel_section .carousel_container .carousel_char_3 {
          left: 10%;
          max-height: 80px; } }
    .carousel_section .carousel_container .carousel_char_4 {
      position: absolute;
      width: 50%;
      height: 50%;
      left: 5%;
      bottom: -16%;
      max-height: 210px;
      background-image: url(/static/media/character_4.35761bf0.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 768px) {
        .carousel_section .carousel_container .carousel_char_4 {
          left: 10%;
          max-height: 160px; } }
    .carousel_section .carousel_container .carousel_char_5 {
      position: absolute;
      width: 50%;
      height: 97.2972973%;
      left: 5%;
      bottom: -16%;
      max-height: 360px;
      background-image: url(/static/media/character_5.d4558713.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 768px) {
        .carousel_section .carousel_container .carousel_char_5 {
          left: 10%;
          max-height: 160px; } }

body.first-component-body {
  overflow: hidden; }
  @media only screen and (max-width: 1024px) {
    body.first-component-body {
      overflow: auto; } }

body.first-component-body-canScroll {
  overflow: auto; }

.screens_first_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box; }
  @media only screen and (max-width: 1024px) {
    .screens_first_section {
      padding-top: 100px;
      display: none; } }
  @media only screen and (max-width: 414px) {
    .screens_first_section {
      height: 600px;
      padding-bottom: 100px;
      padding-top: 50px; } }
  .screens_first_section .banner_image {
    width: 100%;
    height: 100vh;
    margin: 0;
    position: absolute;
    top: -82px;
    left: 0;
    background: url(/static/media/loading_background.90a628de.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    pointer-events: none; }
    .screens_first_section .banner_image__panel {
      width: 100%;
      height: 50%;
      position: absolute;
      background-color: white;
      -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1); }
      .screens_first_section .banner_image__panel--up {
        top: 0;
        -webkit-transform: translateY(calc(-62.4489795918% + 41px));
                transform: translateY(calc(-62.4489795918% + 41px)); }
        @media only screen and (max-width: 414px) {
          .screens_first_section .banner_image__panel--up {
            -webkit-transform: translateY(-142.8px);
                    transform: translateY(-142.8px); } }
        .screens_first_section .banner_image__panel--up.banner_image__panel--closed {
          -webkit-transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          -webkit-transform: translateY(0);
                  transform: translateY(0); }
        .screens_first_section .banner_image__panel--up.banner_image__panel--open {
          -webkit-transform: translateY(-100%);
                  transform: translateY(-100%); }
      .screens_first_section .banner_image__panel--down {
        bottom: 0;
        -webkit-transform: translateY(calc(62.4489795918% + 41px));
                transform: translateY(calc(62.4489795918% + 41px)); }
        @media only screen and (max-width: 414px) {
          .screens_first_section .banner_image__panel--down {
            -webkit-transform: translateY(142.8px);
                    transform: translateY(142.8px); } }
        .screens_first_section .banner_image__panel--down.banner_image__panel--closed {
          -webkit-transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          -webkit-transform: translateY(0);
                  transform: translateY(0); }
        .screens_first_section .banner_image__panel--down.banner_image__panel--open {
          -webkit-transform: translateY(100%);
                  transform: translateY(100%); }
  .screens_first_section .scrollable {
    position: relative;
    top: -72vh;
    width: 100%;
    height: 980vh;
    margin: 0 auto;
    display: flex;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 2s ease-out;
    transition: all 2s ease-out; }
    .screens_first_section .scrollable .left,
    .screens_first_section .scrollable .right {
      width: 50%;
      height: 400vh; }
      .screens_first_section .scrollable .left h2,
      .screens_first_section .scrollable .right h2 {
        margin-bottom: 0; }
      .screens_first_section .scrollable .left .content,
      .screens_first_section .scrollable .right .content {
        width: 50%;
        height: calc(100vh - 82px);
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        -webkit-transform: translateY(32px);
                transform: translateY(32px);
        -webkit-transition: opacity 0.48s 0.16s, -webkit-transform 0.64s;
        transition: opacity 0.48s 0.16s, -webkit-transform 0.64s;
        transition: transform 0.64s, opacity 0.48s 0.16s;
        transition: transform 0.64s, opacity 0.48s 0.16s, -webkit-transform 0.64s;
        pointer-events: none; }
        .screens_first_section .scrollable .left .content--visible,
        .screens_first_section .scrollable .right .content--visible {
          opacity: 1;
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-transition: opacity 0.48s 0.48s, -webkit-transform 0.64s 0.32s;
          transition: opacity 0.48s 0.48s, -webkit-transform 0.64s 0.32s;
          transition: transform 0.64s 0.32s, opacity 0.48s 0.48s;
          transition: transform 0.64s 0.32s, opacity 0.48s 0.48s, -webkit-transform 0.64s 0.32s; }
        .screens_first_section .scrollable .left .content .text,
        .screens_first_section .scrollable .right .content .text {
          width: 100%;
          height: 550px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
    .screens_first_section .scrollable .flag {
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 200vh;
      opacity: 0.2; }
      .screens_first_section .scrollable .flag#secondText {
        top: 400vh; }
      .screens_first_section .scrollable .flag#thirdText {
        top: 600vh; }
      .screens_first_section .scrollable .flag#fourthText {
        top: 800vh; }
      .screens_first_section .scrollable .flag#fifthText {
        top: 980vh; }
    .screens_first_section .scrollable .left .content .text .sx1_title {
      margin-left: 11.1111111112%; }
    .screens_first_section .scrollable .left .content .text .sx1 {
      margin-top: 30px;
      width: 50%;
      margin-left: 11.1111111112%; }
    .screens_first_section .scrollable .left .content .text .sx3 {
      margin-left: 11.1111111112%;
      width: 40%; }
    .screens_first_section .scrollable .right .content .text .dx1 {
      width: 40%;
      margin-left: 40%; }
    .screens_first_section .scrollable .right .content .text .dx2 {
      width: 40%;
      margin-left: 40%; }
    .screens_first_section .scrollable .right .content .text .dx4 {
      margin-top: 30px;
      width: 40%;
      margin-left: 40%; }
    .screens_first_section .scrollable .right .content .text p .exmark {
      font-family: Arial, Helvetica, sans-serif; }
    .screens_first_section .scrollable--visible {
      opacity: 1;
      visibility: visible; }
      @media only screen and (max-width: 768px) {
        .screens_first_section .scrollable--visible {
          display: none; } }
  .screens_first_section .device_hidden {
    display: none;
    opacity: 0;
    position: absolute; }
  .screens_first_section .device {
    width: 368px;
    height: 72vh;
    position: -webkit-sticky;
    position: sticky;
    top: 8%;
    border-radius: 25px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0))), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
    display: flex;
    justify-content: center;
    z-index: 1;
    opacity: 1;
    opacity: 0;
    -webkit-transition: opacity 2s ease-out;
    transition: opacity 2s ease-out; }
    @media only screen and (max-width: 414px) {
      .screens_first_section .device {
        width: 284px;
        height: 489px;
        top: 10%; } }
    .screens_first_section .device .title {
      position: absolute;
      top: 38px; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device .title {
          top: 20px; } }
    .screens_first_section .device .tap_to_start {
      position: absolute;
      top: 290px;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device .tap_to_start {
          top: 120px; } }
    .screens_first_section .device .place_a_character {
      position: absolute;
      bottom: 55px;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device .place_a_character {
          bottom: 20px; } }
    .screens_first_section .device .tap_invite {
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out;
      z-index: 3;
      cursor: pointer; }
      .screens_first_section .device .tap_invite .circle {
        border-radius: 50%;
        background-color: #db1d51;
        width: 120px;
        height: 120px;
        position: absolute;
        opacity: 0;
        -webkit-animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
                animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
        z-index: 2; }
        @media only screen and (max-width: 414px) {
          .screens_first_section .device .tap_invite .circle {
            top: 60px; } }
      .screens_first_section .device .tap_invite .one {
        -webkit-animation-delay: -3s;
                animation-delay: -3s; }
      .screens_first_section .device .tap_invite .two {
        -webkit-animation-delay: -2s;
                animation-delay: -2s; }
      .screens_first_section .device .tap_invite .three {
        -webkit-animation-delay: -1s;
                animation-delay: -1s;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
      .screens_first_section .device .tap_invite .white_dot {
        z-index: 4;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        z-index: 3; }
        @media only screen and (max-width: 414px) {
          .screens_first_section .device .tap_invite .white_dot {
            top: 115px; } }

@keyframes scaleIn {
  from {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(2, 2);
            transform: scale(2, 2);
    opacity: 0; } }

@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(2, 2);
            transform: scale(2, 2);
    opacity: 0; } }
    .screens_first_section .device .enjoy {
      position: absolute;
      top: 330px;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out;
      z-index: 1; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device .enjoy {
          top: 120px; } }
    .screens_first_section .device .character {
      position: absolute;
      bottom: 26px;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out;
      z-index: 1; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device .character {
          height: 40%; } }
  .screens_first_section .device_scroll_hidden {
    opacity: 0;
    visibility: hidden;
    position: absolute; }
  .screens_first_section .device_scroll {
    flex-shrink: 0;
    position: -webkit-sticky;
    position: sticky;
    top: calc(14% + 41px);
    border-radius: 25px;
    box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
    opacity: 1;
    -webkit-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in; }
    @media only screen and (max-width: 1024px) {
      .screens_first_section .device_scroll {
        display: none; } }
    .screens_first_section .device_scroll__background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 25px;
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0))), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
      background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1;
      -webkit-transition: opacity 0.64s ease-in;
      transition: opacity 0.64s ease-in;
      z-index: -1; }
      .screens_first_section .device_scroll__background--variant {
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0))), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/background_variant.e41ee126.png);
        background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/background_variant.e41ee126.png);
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/background_variant.e41ee126.png);
        z-index: -2; }
    .screens_first_section .device_scroll .device_blur {
      width: 100%;
      height: 100%;
      border-radius: 25px;
      position: absolute;
      z-index: -5;
      background-image: -webkit-gradient(linear, left bottom, left top, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0)));
      background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%);
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%);
      opacity: 1;
      -webkit-transition: opacity 2s ease-in;
      transition: opacity 2s ease-in; }
      .screens_first_section .device_scroll .device_blur--hidden {
        opacity: 0; }
    .screens_first_section .device_scroll .title {
      position: absolute;
      top: 38px; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device_scroll .title {
          top: 20px; } }
    .screens_first_section .device_scroll .place_a_character {
      position: absolute;
      bottom: 55px;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device_scroll .place_a_character {
          bottom: 20px; } }
    .screens_first_section .device_scroll .navbar {
      width: 90.7608696%;
      position: absolute;
      top: 20px;
      z-index: 3;
      opacity: 1;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device_scroll .navbar {
          width: 250px; } }
    .screens_first_section .device_scroll .lower {
      width: 93.4604905%;
      position: absolute;
      bottom: 20px;
      z-index: 3;
      opacity: 1;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device_scroll .lower {
          width: 250px; } }
    .screens_first_section .device_scroll .scanning {
      opacity: 1;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
    .screens_first_section .device_scroll .red_grid {
      width: 100%;
      height: 27.1888112vh;
      position: absolute;
      bottom: 0px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      background-image: url(/static/media/red_grid.1bc620ef.svg);
      background-size: cover;
      z-index: 1;
      opacity: 1;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
      @media only screen and (max-width: 414px) {
        .screens_first_section .device_scroll .red_grid {
          height: 38%; } }
    .screens_first_section .device_scroll .three_chars {
      width: 87.4659401%;
      position: absolute;
      bottom: 18.906122449vh;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
    .screens_first_section .device_scroll .dots {
      width: 43.0993007vh;
      position: absolute;
      bottom: 9.7959183673vh;
      left: 40%;
      display: flex;
      justify-content: space-between;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out;
      z-index: 2; }
      .screens_first_section .device_scroll .dots img {
        width: 7.0292887vh; }
    .screens_first_section .device_scroll .character1 {
      width: 16.9174825vh;
      position: absolute;
      bottom: 32%;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
    .screens_first_section .device_scroll .cancel_button {
      width: 3.020979vh;
      position: absolute;
      top: 2.013986vh;
      left: 2.013986vh;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
    .screens_first_section .device_scroll .character2 {
      width: 25.5776224vh;
      position: absolute;
      bottom: 26.5734266%;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
    .screens_first_section .device_scroll .share_buttons {
      width: 33.2307692vh;
      position: absolute;
      bottom: 2.013986vh;
      opacity: 0;
      -webkit-transition: opacity 1.5s ease-out;
      transition: opacity 1.5s ease-out; }
  .screens_first_section .scrollable_visible + .device_scroll {
    pointer-events: none; }
  .screens_first_section .scroll_invite {
    position: fixed;
    bottom: calc(14vh - 41px);
    right: 5.4166666667vw;
    display: flex;
    -webkit-transition: all 1s ease-in;
    transition: all 1s ease-in; }
    .screens_first_section .scroll_invite .learn_more {
      margin-top: 50px;
      margin-left: 13px; }
    @media only screen and (max-width: 414px) {
      .screens_first_section .scroll_invite {
        margin-left: 65px;
        bottom: -55px; } }
  .screens_first_section--appear .banner_image__panel--up,
  .screens_first_section--appear .banner_image__panel--down, .screens_first_section--enter .banner_image__panel--up,
  .screens_first_section--enter .banner_image__panel--down {
    -webkit-transition: none;
    transition: none; }
  .screens_first_section--appear .banner_image__panel--up, .screens_first_section--enter .banner_image__panel--up {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  .screens_first_section--appear .banner_image__panel--down, .screens_first_section--enter .banner_image__panel--down {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  .screens_first_section--appear .device_scroll, .screens_first_section--enter .device_scroll {
    -webkit-transition: none;
    transition: none;
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); }
  .screens_first_section--appear-active .banner_image__panel--up,
  .screens_first_section--appear-active .banner_image__panel--down, .screens_first_section--enter-active .banner_image__panel--up,
  .screens_first_section--enter-active .banner_image__panel--down {
    -webkit-transition: -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition: -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1); }
  .screens_first_section--appear-active .banner_image__panel--up, .screens_first_section--enter-active .banner_image__panel--up {
    -webkit-transform: translateY(calc(-62.4489795918% + 41px));
            transform: translateY(calc(-62.4489795918% + 41px)); }
    @media only screen and (max-width: 414px) {
      .screens_first_section--appear-active .banner_image__panel--up, .screens_first_section--enter-active .banner_image__panel--up {
        -webkit-transform: translateY(-142.8px);
                transform: translateY(-142.8px); } }
  .screens_first_section--appear-active .banner_image__panel--down, .screens_first_section--enter-active .banner_image__panel--down {
    -webkit-transform: translateY(calc(62.4489795918% + 41px));
            transform: translateY(calc(62.4489795918% + 41px)); }
    @media only screen and (max-width: 414px) {
      .screens_first_section--appear-active .banner_image__panel--down, .screens_first_section--enter-active .banner_image__panel--down {
        -webkit-transform: translateY(142.8px);
                transform: translateY(142.8px); } }
  .screens_first_section--appear-active .device_scroll, .screens_first_section--enter-active .device_scroll {
    -webkit-transition: 1s 1.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: 1s 1.5s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  .screens_first_section--exit .banner_image__panel--up,
  .screens_first_section--exit .banner_image__panel--down {
    -webkit-transition: none;
    transition: none; }
  .screens_first_section--exit .banner_image__panel--up {
    -webkit-transform: translateY(-62.44898%);
            transform: translateY(-62.44898%); }
    @media only screen and (max-width: 414px) {
      .screens_first_section--exit .banner_image__panel--up {
        -webkit-transform: translateY(-142.8px);
                transform: translateY(-142.8px); } }
  .screens_first_section--exit .banner_image__panel--down {
    -webkit-transform: translateY(62.44898%);
            transform: translateY(62.44898%); }
    @media only screen and (max-width: 414px) {
      .screens_first_section--exit .banner_image__panel--down {
        -webkit-transform: translateY(142.8px);
                transform: translateY(142.8px); } }
  .screens_first_section--exit-active .banner_image__panel--up,
  .screens_first_section--exit-active .banner_image__panel--down {
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translateY(0);
            transform: translateY(0); }

.screens_first_mobile_only {
  display: none; }
  @media only screen and (max-width: 1024px) {
    .screens_first_mobile_only {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 150px;
      width: 100%;
      height: calc(100vh - 82px);
      position: relative;
      top: 0;
      left: 0;
      box-sizing: border-box; }
      .screens_first_mobile_only .banner_image {
        width: 100%;
        height: 100vh;
        margin: 0;
        position: absolute;
        top: -82px;
        left: 0;
        background: url(/static/media/loading_background.90a628de.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden; }
        .screens_first_mobile_only .banner_image__panel {
          width: 100%;
          height: 50%;
          position: absolute;
          background-color: white;
          -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
          transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
          transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
          transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1); }
          .screens_first_mobile_only .banner_image__panel--up {
            top: 0;
            -webkit-transform: translateY(-216px);
                    transform: translateY(-216px); } }
        @media only screen and (max-width: 1024px) and (max-width: 414px) {
          .screens_first_mobile_only .banner_image__panel--up {
            -webkit-transform: translateY(-142.8px);
                    transform: translateY(-142.8px); } }
  @media only screen and (max-width: 1024px) {
            .screens_first_mobile_only .banner_image__panel--up.banner_image__panel--closed {
              -webkit-transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
              transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
              transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
              transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
              -webkit-transform: translateY(0);
                      transform: translateY(0); }
            .screens_first_mobile_only .banner_image__panel--up.banner_image__panel--open {
              -webkit-transform: translateY(-100%);
                      transform: translateY(-100%); }
          .screens_first_mobile_only .banner_image__panel--down {
            bottom: 0;
            -webkit-transform: translateY(216px);
                    transform: translateY(216px); } }
        @media only screen and (max-width: 1024px) and (max-width: 414px) {
          .screens_first_mobile_only .banner_image__panel--down {
            -webkit-transform: translateY(142.8px);
                    transform: translateY(142.8px); } }
  @media only screen and (max-width: 1024px) {
            .screens_first_mobile_only .banner_image__panel--down.banner_image__panel--closed {
              -webkit-transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
              transition: -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
              transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
              transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 1s cubic-bezier(0.86, 0, 0.07, 1);
              -webkit-transform: translateY(0);
                      transform: translateY(0); }
            .screens_first_mobile_only .banner_image__panel--down.banner_image__panel--open {
              -webkit-transform: translateY(100%);
                      transform: translateY(100%); }
      .screens_first_mobile_only .device {
        background-color: tomato;
        position: -webkit-sticky;
        position: sticky;
        top: 8%;
        border-radius: 25px;
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0))), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
        background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
        display: flex;
        justify-content: center;
        z-index: 1; } }
    @media only screen and (max-width: 1024px) and (max-width: 414px) {
      .screens_first_mobile_only .device {
        position: absolute;
        top: calc((100% - 72vh) / 2 - 41px); } }
  @media only screen and (max-width: 1024px) {
        .screens_first_mobile_only .device .title {
          position: absolute;
          top: 38px; } }
      @media only screen and (max-width: 1024px) and (max-width: 414px) {
        .screens_first_mobile_only .device .title {
          top: 20px; } }
  @media only screen and (max-width: 1024px) {
        .screens_first_mobile_only .device .place_a_character {
          position: absolute;
          bottom: 55px;
          -webkit-transition: opacity 1.5s ease-out;
          transition: opacity 1.5s ease-out; } }
      @media only screen and (max-width: 1024px) and (max-width: 414px) {
        .screens_first_mobile_only .device .place_a_character {
          bottom: 20px; } }
  @media only screen and (max-width: 1024px) {
      .screens_first_mobile_only .scroll_invite {
        position: fixed;
        margin-left: 290px;
        bottom: 0;
        display: flex;
        -webkit-transition: all 1s ease-in;
        transition: all 1s ease-in; }
        .screens_first_mobile_only .scroll_invite .learn_more {
          margin-top: 50px;
          margin-left: 13px; } }
    @media only screen and (max-width: 1024px) and (max-width: 716px) {
      .screens_first_mobile_only .scroll_invite {
        margin-left: 65px;
        bottom: 0; } }
  @media only screen and (max-width: 1024px) {
      .screens_first_mobile_only--appear .banner_image__panel--up,
      .screens_first_mobile_only--appear .banner_image__panel--down, .screens_first_mobile_only--enter .banner_image__panel--up,
      .screens_first_mobile_only--enter .banner_image__panel--down {
        -webkit-transition: none;
        transition: none; }
      .screens_first_mobile_only--appear .banner_image__panel--up, .screens_first_mobile_only--enter .banner_image__panel--up {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%); }
      .screens_first_mobile_only--appear .banner_image__panel--down, .screens_first_mobile_only--enter .banner_image__panel--down {
        -webkit-transform: translateY(100%);
                transform: translateY(100%); }
      .screens_first_mobile_only--appear .device, .screens_first_mobile_only--enter .device {
        -webkit-transition: none;
        transition: none;
        opacity: 0;
        -webkit-transform: translateY(50px);
                transform: translateY(50px); }
      .screens_first_mobile_only--appear-active .banner_image__panel--up,
      .screens_first_mobile_only--appear-active .banner_image__panel--down, .screens_first_mobile_only--enter-active .banner_image__panel--up,
      .screens_first_mobile_only--enter-active .banner_image__panel--down {
        -webkit-transition: -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
        transition: -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
        transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
        transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1); }
      .screens_first_mobile_only--appear-active .banner_image__panel--up, .screens_first_mobile_only--enter-active .banner_image__panel--up {
        -webkit-transform: translateY(-216px);
                transform: translateY(-216px); } }
    @media only screen and (max-width: 1024px) and (max-width: 414px) {
      .screens_first_mobile_only--appear-active .banner_image__panel--up, .screens_first_mobile_only--enter-active .banner_image__panel--up {
        -webkit-transform: translateY(-142.8px);
                transform: translateY(-142.8px); } }
  @media only screen and (max-width: 1024px) {
      .screens_first_mobile_only--appear-active .banner_image__panel--down, .screens_first_mobile_only--enter-active .banner_image__panel--down {
        -webkit-transform: translateY(216px);
                transform: translateY(216px); } }
    @media only screen and (max-width: 1024px) and (max-width: 414px) {
      .screens_first_mobile_only--appear-active .banner_image__panel--down, .screens_first_mobile_only--enter-active .banner_image__panel--down {
        -webkit-transform: translateY(142.8px);
                transform: translateY(142.8px); } }
  @media only screen and (max-width: 1024px) {
      .screens_first_mobile_only--appear-active .device, .screens_first_mobile_only--enter-active .device {
        -webkit-transition: 1s 1.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition: 1s 1.5s cubic-bezier(0.23, 1, 0.32, 1);
        -webkit-transition-property: opacity, -webkit-transform;
        transition-property: opacity, -webkit-transform;
        transition-property: opacity, transform;
        transition-property: opacity, transform, -webkit-transform;
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0); }
      .screens_first_mobile_only--exit .banner_image__panel--up,
      .screens_first_mobile_only--exit .banner_image__panel--down {
        -webkit-transition: none;
        transition: none; }
      .screens_first_mobile_only--exit .banner_image__panel--up {
        -webkit-transform: translateY(-216px);
                transform: translateY(-216px); } }
    @media only screen and (max-width: 1024px) and (max-width: 414px) {
      .screens_first_mobile_only--exit .banner_image__panel--up {
        -webkit-transform: translateY(-142.8px);
                transform: translateY(-142.8px); } }
  @media only screen and (max-width: 1024px) {
      .screens_first_mobile_only--exit .banner_image__panel--down {
        -webkit-transform: translateY(216px);
                transform: translateY(216px); } }
    @media only screen and (max-width: 1024px) and (max-width: 414px) {
      .screens_first_mobile_only--exit .banner_image__panel--down {
        -webkit-transform: translateY(142.8px);
                transform: translateY(142.8px); } }
  @media only screen and (max-width: 1024px) {
      .screens_first_mobile_only--exit-active .banner_image__panel--up,
      .screens_first_mobile_only--exit-active .banner_image__panel--down {
        -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition: -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        -webkit-transform: translateY(0);
                transform: translateY(0); } }

@media only screen and (max-width: 1024px) {
  .desktop_only {
    display: none; } }

.mobile_only {
  display: none; }
  @media only screen and (max-width: 1024px) {
    .mobile_only {
      display: block; }
      .mobile_only .container {
        width: 90%;
        height: 2800px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center; }
        .mobile_only .container .title {
          order: 1; }
        .mobile_only .container .title2 {
          order: 2; } }
      @media only screen and (max-width: 1024px) and (max-width: 768px) {
        .mobile_only .container .title2 {
          text-align: center;
          width: 276px; } }
  @media only screen and (max-width: 1024px) {
        .mobile_only .container .device1 {
          width: 276px;
          height: 489px;
          position: relative;
          top: 1%;
          border-radius: 25px;
          background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5.87%, rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0))), -webkit-gradient(linear, left bottom, left top, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0))), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0) 100%), -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
          display: flex;
          justify-content: center;
          z-index: 2;
          order: 3; }
          .mobile_only .container .device1 .navbar {
            width: 90%;
            position: absolute;
            top: 20px;
            z-index: 3; }
          .mobile_only .container .device1 .lower {
            width: 90%;
            position: absolute;
            bottom: 20px;
            z-index: 3; }
          .mobile_only .container .device1 .red_grid {
            width: 100%;
            height: 38%;
            position: absolute;
            bottom: 0px;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            background-image: url(/static/media/red_grid.1bc620ef.svg);
            background-size: cover;
            z-index: 1; }
        .mobile_only .container p {
          max-width: 276px;
          text-align: center;
          margin-top: 24px; }
        .mobile_only .container .desc1 {
          order: 4; }
        .mobile_only .container .device2 {
          width: 276px;
          height: 489px;
          position: relative;
          top: 1%;
          border-radius: 25px;
          background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5.87%, rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0))), -webkit-gradient(linear, left bottom, left top, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0))), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/background_variant.e41ee126.png);
          background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0) 100%), -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/background_variant.e41ee126.png);
          background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/background_variant.e41ee126.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
          display: flex;
          justify-content: center;
          z-index: 2;
          order: 5; }
          .mobile_only .container .device2 .navbar {
            width: 90%;
            position: absolute;
            top: 20px;
            z-index: 3; }
          .mobile_only .container .device2 .lower {
            width: 90%;
            position: absolute;
            bottom: 20px;
            z-index: 3; }
          .mobile_only .container .device2 .three_chars {
            width: 90%;
            position: absolute;
            bottom: 125px; }
          .mobile_only .container .device2 .dots {
            width: 15%;
            position: absolute;
            bottom: 75px; }
        .mobile_only .container .scrollable {
          overflow: auto;
          position: absolute;
          width: 100%;
          height: 50px;
          bottom: 70px;
          left: 0; }
          .mobile_only .container .scrollable .dots {
            height: 50px;
            position: absolute;
            top: 0;
            left: 37%;
            display: flex;
            justify-content: space-between;
            z-index: 2; }
        .mobile_only .container .scrollable::-webkit-scrollbar {
          display: none; }
        .mobile_only .container .desc2 {
          order: 6; }
        .mobile_only .container .device3 {
          width: 276px;
          height: 489px;
          position: relative;
          top: 1%;
          border-radius: 25px;
          background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5.87%, rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0))), -webkit-gradient(linear, left bottom, left top, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0))), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0) 100%), -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
          display: flex;
          justify-content: center;
          z-index: 2;
          order: 7; }
          .mobile_only .container .device3 .navbar {
            width: 90%;
            position: absolute;
            top: 20px;
            z-index: 3; }
          .mobile_only .container .device3 .lower {
            width: 90%;
            position: absolute;
            bottom: 20px;
            z-index: 3; }
          .mobile_only .container .device3 .character1 {
            width: 50%;
            position: absolute;
            bottom: 135px; }
        .mobile_only .container .scrollable2 {
          overflow: auto;
          position: absolute;
          width: 100%;
          height: 50px;
          bottom: 70px;
          left: 0; }
          .mobile_only .container .scrollable2 .dots2 {
            height: 50px;
            position: absolute;
            top: 0;
            left: 37%;
            display: flex;
            justify-content: space-between;
            z-index: 2; }
        .mobile_only .container .scrollable2::-webkit-scrollbar {
          display: none; }
        .mobile_only .container .desc3 {
          order: 8; }
        .mobile_only .container .device4 {
          width: 276px;
          height: 489px;
          position: relative;
          top: 1%;
          border-radius: 25px;
          background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5.87%, rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0))), -webkit-gradient(linear, left bottom, left top, color-stop(5.87%, rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0))), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0) 100%), -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 0.4) 5.87%, rgba(0, 0, 0, 0) 100%), url(/static/media/screens_blur_ellipse.4acdb004.svg), url(/static/media/loading_background.90a628de.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
          display: flex;
          justify-content: center;
          z-index: 2;
          order: 9; }
          .mobile_only .container .device4 .cancel_button {
            width: 8%;
            position: absolute;
            top: 20px;
            left: 20px;
            order: 1; }
          .mobile_only .container .device4 .character2 {
            width: 50%;
            position: absolute;
            bottom: 100px;
            order: 2; }
          .mobile_only .container .device4 .share_buttons {
            width: 90%;
            position: absolute;
            bottom: 20px;
            order: 3; }
        .mobile_only .container .desc4 {
          order: 10; } }

p {
  font-size: 16px;
  line-height: 20px; }
  @media (min-width: 1024px) {
    p {
      font-size: 20px;
      line-height: 22px; } }

h1,
h2 {
  font-size: 53px;
  line-height: 54px;
  font-weight: 500; }
  @media only screen and (max-width: 768px) {
    h1,
    h2 {
      font-size: 40px;
      line-height: 40px; } }

