.screens_first_mobile_only {
  display: none;

  @media only screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 150px;

    width: 100%;
    height: calc(100vh - 82px);

    position: relative;
    top: 0;
    left: 0;

    box-sizing: border-box;

    .banner_image {
      width: 100%;
      height: 100vh;
      margin: 0;

      position: absolute;
      top: -82px;
      left: 0;

      background: url("../../images/loading_background.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      overflow: hidden;

      &__panel {
        width: 100%;
        height: 50%;

        position: absolute;

        background-color: white;
        transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);

        &--up {
          top: 0;
          transform: translateY(-216px);

          @media only screen and (max-width: 414px) {
            transform: translateY(-142.8px);
          }

          &.banner_image__panel--closed {
            transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
            transform: translateY(0);
          }
          &.banner_image__panel--open {
            transform: translateY(-100%);
          }
        }
        &--down {
          bottom: 0;
          transform: translateY(216px);

          @media only screen and (max-width: 414px) {
            transform: translateY(142.8px);
          }

          &.banner_image__panel--closed {
            transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
            transform: translateY(0);
          }
          &.banner_image__panel--open {
            transform: translateY(100%);
          }
        }
      }
    }

    .device {
      background-color: tomato;
      position: sticky;
      top: 8%;
      border-radius: 25px;
      background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4) 5.87%,
          rgba(0, 0, 0, 0) 100%
        ),
        url("../../images/screens_blur_ellipse.svg"),
        url("../../images/loading_background.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
      display: flex;
      justify-content: center;
      z-index: 1;

      @media only screen and (max-width: 414px) {
        position: absolute;
        top: calc((100% - 72vh) / 2 - 41px);
      }

      .title {
        position: absolute;
        top: 38px;

        @media only screen and (max-width: 414px) {
          top: 20px;
        }
      }

      .place_a_character {
        position: absolute;
        bottom: 55px;
        transition: opacity 1.5s ease-out;

        @media only screen and (max-width: 414px) {
          bottom: 20px;
        }
      }
    }

    .scroll_invite {
      position: fixed;
      margin-left: 290px;
      bottom: 0;
      display: flex;
      transition: all 1s ease-in;

      .learn_more {
        margin-top: 50px;
        margin-left: 13px;
      }

      @media only screen and (max-width: 716px) {
        margin-left: 65px;
        bottom: 0;
      }
    }

    &--appear,
    &--enter {
      .banner_image__panel--up,
      .banner_image__panel--down {
        transition: none;
      }
      .banner_image__panel--up {
        transform: translateY(-100%);
      }
      .banner_image__panel--down {
        transform: translateY(100%);
      }

      .device {
        transition: none;
        opacity: 0;
        transform: translateY(50px);
      }

      &-active {
        .banner_image__panel--up,
        .banner_image__panel--down {
          transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
        }
        .banner_image__panel--up {
          transform: translateY(-216px);

          @media only screen and (max-width: 414px) {
            transform: translateY(-142.8px);
          }
        }
        .banner_image__panel--down {
          transform: translateY(216px);

          @media only screen and (max-width: 414px) {
            transform: translateY(142.8px);
          }
        }

        .device {
          transition: 1s 1.5s cubic-bezier(0.23, 1, 0.32, 1);
          transition-property: opacity, transform;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    &--exit {
      .banner_image__panel--up,
      .banner_image__panel--down {
        transition: none;
      }
      .banner_image__panel--up {
        transform: translateY(-216px);

        @media only screen and (max-width: 414px) {
          transform: translateY(-142.8px);
        }
      }
      .banner_image__panel--down {
        transform: translateY(216px);

        @media only screen and (max-width: 414px) {
          transform: translateY(142.8px);
        }
      }

      &-active {
        .banner_image__panel--up,
        .banner_image__panel--down {
          transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
          transform: translateY(0);
        }
      }
    }
  }
}
