body {
  &.first-component-body {
    overflow: hidden;

    @media only screen and (max-width: 1024px) {
      overflow: auto;
    }
  }

  &.first-component-body-canScroll {
    overflow: auto;
  }
}

.screens_first_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  position: relative;
  top: 0;
  left: 0;

  box-sizing: border-box;

  @media only screen and (max-width: 1024px) {
    padding-top: 100px;
    display: none;
  }

  @media only screen and (max-width: 414px) {
    height: 600px;
    padding-bottom: 100px;
    padding-top: 50px;
  }

  .banner_image {
    width: 100%;
    height: 100vh;
    margin: 0;

    position: absolute;
    top: -82px;
    left: 0;

    background: url("../../images/loading_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    overflow: hidden;
    pointer-events: none;

    &__panel {
      width: 100%;
      height: 50%;

      position: absolute;

      background-color: white;
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);

      &--up {
        top: 0;
        transform: translateY(calc(-62.4489795918% + 41px));

        @media only screen and (max-width: 414px) {
          transform: translateY(-142.8px);
        }

        &.banner_image__panel--closed {
          transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transform: translateY(0);
        }
        &.banner_image__panel--open {
          transform: translateY(-100%);
        }
      }

      &--down {
        bottom: 0;
        transform: translateY(calc(62.4489795918% + 41px));

        @media only screen and (max-width: 414px) {
          transform: translateY(142.8px);
        }

        &.banner_image__panel--closed {
          transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
          transform: translateY(0);
        }

        &.banner_image__panel--open {
          transform: translateY(100%);
        }
      }
    }
  }

  .scrollable {
    position: relative;
    top: -72vh;
    width: 100%;
    height: 980vh;
    margin: 0 auto;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: all 2s ease-out;

    .left,
    .right {
      width: 50%;
      height: 400vh;

      h2 {
        margin-bottom: 0;
      }

      .content {
        width: 50%;
        height: calc(100vh - 82px);
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        transform: translateY(32px);
        transition: transform 0.64s, opacity 0.48s 0.16s;
        pointer-events: none;

        &--visible {
          opacity: 1;
          transform: translateY(0);
          transition: transform 0.64s 0.32s, opacity 0.48s 0.48s;
        }

        .text {
          width: 100%;
          height: 550px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }
    }

    .flag {
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 200vh;
      opacity: 0.2;

      &#secondText {
        top: 400vh;
      }

      &#thirdText {
        top: 600vh;
      }

      &#fourthText {
        top: 800vh;
      }

      &#fifthText {
        top: 980vh;
      }
    }

    .left {
      .content .text {
        .sx1_title {
          margin-left: 11.1111111112%;
        }

        .sx1 {
          margin-top: 30px;
          width: 50%;
          margin-left: 11.1111111112%;
        }

        .sx3 {
          margin-left: 11.1111111112%;
          width: 40%;
        }
      }
    }

    .right {
      .content .text {
        .dx1 {
          width: 40%;
          margin-left: 40%;
        }

        .dx2 {
          width: 40%;
          margin-left: 40%;
        }

        .dx4 {
          margin-top: 30px;
          width: 40%;

          margin-left: 40%;
        }

        p {
          .exmark {
            font-family: Arial, Helvetica, sans-serif;
          }
        }
      }
    }

    &--visible {
      opacity: 1;
      visibility: visible;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .device_hidden {
    display: none;
    opacity: 0;
    position: absolute;
  }

  .device {
    width: 368px;
    height: 72vh;
    position: sticky;
    top: 8%;
    border-radius: 25px;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 5.87%,
        rgba(0, 0, 0, 0) 100%
      ),
      url("../../images/screens_blur_ellipse.svg"),
      url("../../images/loading_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
    display: flex;
    justify-content: center;
    z-index: 1;
    opacity: 1;
    opacity: 0;
    transition: opacity 2s ease-out;

    @media only screen and (max-width: 414px) {
      width: 284px;
      height: 489px;
      top: 10%;
    }

    .title {
      position: absolute;
      top: 38px;

      @media only screen and (max-width: 414px) {
        top: 20px;
      }
    }

    .tap_to_start {
      position: absolute;
      top: 290px;
      transition: opacity 1.5s ease-out;
      @media only screen and (max-width: 414px) {
        top: 120px;
      }
    }

    .place_a_character {
      position: absolute;
      bottom: 55px;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 414px) {
        bottom: 20px;
      }
    }

    .tap_invite {
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 1.5s ease-out;
      z-index: 3;
      cursor: pointer;

      .circle {
        border-radius: 50%;
        background-color: #db1d51;
        width: 120px;
        height: 120px;
        position: absolute;
        opacity: 0;
        animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
        z-index: 2;

        @media only screen and (max-width: 414px) {
          top: 60px;
        }
      }

      .one {
        animation-delay: -3s;
      }
      .two {
        animation-delay: -2s;
      }
      .three {
        animation-delay: -1s;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .white_dot {
        z-index: 4;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        z-index: 3;

        @media only screen and (max-width: 414px) {
          top: 115px;
        }
      }

      @keyframes scaleIn {
        from {
          transform: scale(0.5, 0.5);
          opacity: 0.5;
        }
        to {
          transform: scale(2, 2);
          opacity: 0;
        }
      }

      @-webkit-keyframes scaleIn {
        from {
          transform: scale(0.5, 0.5);
          opacity: 0.5;
        }
        to {
          transform: scale(2, 2);
          opacity: 0;
        }
      }
    }

    .enjoy {
      position: absolute;
      top: 330px;
      opacity: 0;
      transition: opacity 1.5s ease-out;
      z-index: 1;

      @media only screen and (max-width: 414px) {
        top: 120px;
      }
    }

    .character {
      position: absolute;
      bottom: 26px;
      opacity: 0;
      transition: opacity 1.5s ease-out;
      z-index: 1;

      @media only screen and (max-width: 414px) {
        height: 40%;
      }
    }
  }

  .device_scroll_hidden {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  .device_scroll {
    flex-shrink: 0;
    position: sticky;
    top: calc(14% + 41px);
    border-radius: 25px;
    box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
    opacity: 1;
    transition: opacity 1s ease-in;

    @media only screen and (max-width: 1024px) {
      display: none;
    }

    &__background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 25px;
      background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4) 5.87%,
          rgba(0, 0, 0, 0) 100%
        ),
        url("../../images/screens_blur_ellipse.svg"),
        url("../../images/loading_background.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1;
      transition: opacity 0.64s ease-in;
      z-index: -1;

      &--variant {
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.4) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          url("../../images/screens_blur_ellipse.svg"),
          url("../../images/background_variant.png");
        z-index: -2;
      }
    }

    .device_blur {
      width: 100%;
      height: 100%;
      border-radius: 25px;
      position: absolute;
      z-index: -5;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.4) 5.87%,
        rgba(0, 0, 0, 0) 100%
      );
      opacity: 1;
      transition: opacity 2s ease-in;

      &--hidden {
        opacity: 0;
      }
    }

    .title {
      position: absolute;
      top: 38px;

      @media only screen and (max-width: 414px) {
        top: 20px;
      }
    }

    .place_a_character {
      position: absolute;
      bottom: 55px;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 414px) {
        bottom: 20px;
      }
    }

    // fixed elements
    .navbar {
      width: 90.7608696%;

      position: absolute;
      top: 20px;
      z-index: 3;

      opacity: 1;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 414px) {
        width: 250px;
      }
    }

    .lower {
      width: 93.4604905%;

      position: absolute;
      bottom: 20px;
      z-index: 3;
      opacity: 1;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 414px) {
        width: 250px;
      }
    }

    .scanning {
      opacity: 1;
      transition: opacity 1.5s ease-out;
    }

    // elements that will move on scroll
    .red_grid {
      width: 100%;
      height: 27.1888112vh;
      position: absolute;
      bottom: 0px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      background-image: url("../../images/red_grid.svg");
      background-size: cover;
      z-index: 1;
      opacity: 1;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 414px) {
        height: 38%;
      }
    }

    .three_chars {
      width: 87.4659401%;

      position: absolute;
      bottom: 18.906122449vh;
      opacity: 0;
      transition: opacity 1.5s ease-out;
    }

    .dots {
      width: 43.0993007vh;
      position: absolute;
      bottom: 9.7959183673vh;
      left: 40%;
      display: flex;
      justify-content: space-between;
      opacity: 0;
      transition: opacity 1.5s ease-out;
      z-index: 2;
      img {
        width: 7.0292887vh;
      }
    }

    .character1 {
      width: 16.9174825vh;

      position: absolute;
      bottom: 32%;

      opacity: 0;
      transition: opacity 1.5s ease-out;
    }

    .cancel_button {
      width: 3.020979vh;

      position: absolute;
      top: 2.013986vh;
      left: 2.013986vh;

      opacity: 0;
      transition: opacity 1.5s ease-out;
    }

    .character2 {
      width: 25.5776224vh;

      position: absolute;
      bottom: 26.5734266%;

      opacity: 0;
      transition: opacity 1.5s ease-out;
    }

    .share_buttons {
      width: 33.2307692vh;

      position: absolute;
      bottom: 2.013986vh;

      opacity: 0;
      transition: opacity 1.5s ease-out;
    }
  }

  .scrollable_visible + .device_scroll {
    pointer-events: none;
  }

  .scroll_invite {
    position: fixed;
    bottom: calc(14vh - 41px);
    right: 5.4166666667vw;
    display: flex;
    transition: all 1s ease-in;

    .learn_more {
      margin-top: 50px;
      margin-left: 13px;
    }

    @media only screen and (max-width: 414px) {
      margin-left: 65px;
      bottom: -55px;
    }
  }

  &--appear,
  &--enter {
    .banner_image__panel--up,
    .banner_image__panel--down {
      transition: none;
    }
    .banner_image__panel--up {
      transform: translateY(-100%);
    }
    .banner_image__panel--down {
      transform: translateY(100%);
    }

    .device_scroll {
      transition: none;
      opacity: 0;
      transform: translateY(50px);
    }

    &-active {
      .banner_image__panel--up,
      .banner_image__panel--down {
        transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
      }

      .banner_image__panel--up {
        transform: translateY(calc(-62.4489795918% + 41px));

        @media only screen and (max-width: 414px) {
          transform: translateY(-142.8px);
        }
      }

      .banner_image__panel--down {
        transform: translateY(calc(62.4489795918% + 41px));

        @media only screen and (max-width: 414px) {
          transform: translateY(142.8px);
        }
      }

      .device_scroll {
        transition: 1s 1.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition-property: opacity, transform;
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  &--exit {
    .banner_image__panel--up,
    .banner_image__panel--down {
      transition: none;
    }
    .banner_image__panel--up {
      transform: translateY(-62.4489795918%);

      @media only screen and (max-width: 414px) {
        transform: translateY(-142.8px);
      }
    }
    .banner_image__panel--down {
      transform: translateY(62.4489795918%);

      @media only screen and (max-width: 414px) {
        transform: translateY(142.8px);
      }
    }

    &-active {
      .banner_image__panel--up,
      .banner_image__panel--down {
        transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        transform: translateY(0);
      }
    }
  }
}
