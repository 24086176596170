p {
  font-size: 16px;
  line-height: 20px;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 22px;
  }
}

h1,
h2 {
  font-size: 53px;
  line-height: 54px;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 40px;
  }
}