.cookies-banner {
  position: fixed;
  bottom: 0;
  background: #db1d51;
  padding: 16px 16px;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.4s ease-out;

  @media (min-width: 1024px) {
    padding: 16px 37px;
  }

  &--hidden {
    opacity: 0;
  }

  &--unmounted {
    display: none;
  }

  span {
    color: white;
    font-weight: 100;
    font-size: 12px;
    flex-grow: 1;

    @media (min-width: 1024px) {
      font-size: 15px;
    }
  }

  a {
    font-size: 11px;
    margin-left: 8px;
    border: 1px solid white;
    color: white;
    padding: 6px 12px;
    background: #db1d51;
    transition: all 0.48s ease-out;
    border-radius: 32px;
    text-decoration: none;
    white-space: nowrap;
    width: fit-content;

    @media (min-width: 1024px) {
      font-size: 13px;
      padding: 8px 16px;
    }

    &:hover {
      background: white;
      color: #db1d51;
    }

    &:last-of-type {
      background: white;
      color: #db1d51;
    }
  }
}
