.features {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 150px auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  @media (min-width: 1024px) {
    flex-direction: row;
    text-align: initial;
    margin-bottom: 112px;
    margin-top: 19.387755102vh;
  }

  .technical_features {
    width: 100%;
    margin-bottom: 32px;

    @media (min-width: 1024px) {
      width: 40%;
      margin-bottom: 0;
    }

    .title {
      margin-bottom: 25px;
    }

    p {
      font-size: 16px;
      margin: 0 auto;

      @media (min-width: 1024px) {
        font-size: 24px;
        line-height: 29px;
        margin: 0 auto 80px;
      }

      .p-mobile {
        display: inline-block;

        @media (min-width: 1024px) {
          display: none;
        }
      }

      .p-desktop {
        display: none;

        @media (min-width: 1024px) {
          display: block;
        }
      }
    }

    .slider {
      ul.slider-content {
        @media (min-width: 1024px) {
          margin-left: 0;
          padding-left: 0;
        }

        li {
          vertical-align: middle;
          transform: translateX(calc(-100% * var(--slider-constant--1)));

          @media (min-width: 1024px) {
            vertical-align: bottom;
          }

          &::before {
            display: none;
          }

          p {
            @media (min-width: 1024px) {
              text-align: left;
            }
          }
        }
      }
    }

    ul.slider-controller {
      margin-bottom: 0;
      margin-top: 25px;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: 1024px) {
        margin-top: 80px;
        margin-left: 0;
        transform: none;
        position: static;
      }
    }
  }

  .slide_gallery {
    width: 100%;
    height: auto;

    @media (min-width: 1024px) {
      width: 558px;
      height: 477px;
    }

    .stack {
      width: 100%;
      height: 378px;
      position: relative;
      flex: 1 1 auto;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;

      @media (min-width: 1024px) {
        height: 477px;
      }
    }

    .card {
      flex: 1 1 auto;
      width: 100%;
      height: 378px;
      position: absolute;
      opacity: 1;
      transition: 0.7s cubic-bezier(0.23, 1, 0.32, 1);
      transition-property: opacity, transform;
      transform-origin: right;

      @media (min-width: 1024px) {
        height: 477px;
      }
    }

    .card1 {
      background-image: url("../../images/features_img1.jpg");
      background-size: cover;
    }

    .card2 {
      background-image: url("../../images/features_img2.jpg");
      background-size: cover;
    }

    .card3 {
      background-image: url("../../images/features_img3.jpg");
      background-size: cover;
    }

    .first {
      z-index: 3;
      transform: translateX(0) scale(1);
    }

    .second {
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.3);
      background-blend-mode: darken;
      transform: translateX(4vw) scale(0.9);

      @media (min-width: 1024px) {
        transform: translateX(1.8vw) scale(0.9);
      }
    }

    .third {
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.3);
      background-blend-mode: darken;
      transform: translateX(8vw) scale(0.8);

      @media (min-width: 1024px) {
        transform: translateX(3.6vw) scale(0.8);
      }
    }
  }
}
