.slider {
  display: flex;
  flex-direction: column;

  ul {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    padding: 0 20px;

    white-space: nowrap;
    overflow: hidden;

    box-sizing: border-box;

    &.slider-controller {
      width: auto;
      margin-top: 82px;
      margin-bottom: 112px;
      padding: 0;

      li {
        width: auto;
        font-size: 0;

        a {
          display: inline-block;
          padding: 4px;

          transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
          opacity: 0.2;

          cursor: pointer;

          &::before {
            content: "";
            display: inline-block;
            width: 31px;
            height: 2px;

            background: black;
          }

          &.active {
            opacity: 1;
          }
        }
      }
    }

    &.slider-content {
      li {
        opacity: 0;
        transform: translateX(calc(-100% * var(--slider-constant)));
        transition: transform 0.8s 0.4s cubic-bezier(0.23, 1, 0.32, 1),
          opacity 0.6s;

        &.active {
          transition: opacity 0.6s 0.4s;
          opacity: 1;
        }

        &::before {
          content: "“";

          margin-bottom: -19px;

          font-size: 78px;
          line-height: 112px;
          text-align: center;
          text-transform: uppercase;

          color: #db1d51;
        }
      }
    }

    li {
      display: inline-block;
      width: 100%;
      white-space: normal;
      color: black;

      text-align: center;

      user-select: none;
      vertical-align: bottom;

      @media only screen and (min-width: 768px) {
        vertical-align: middle;
      }

      p {
        margin: 0;
        font-size: 30px;
        line-height: 1.2em;
      }
    }
  }

  &__author {
    margin-top: 40px;

    font-size: 17px;
    line-height: 1.2em;
    text-align: center;

    color: #db1d51;
  }

  &__badge {
    margin: 0;
    width: 198px;
    height: 61px;
    background-color: #004bd3;
    border-radius: 63.5px;
    background-image: url("../../images/6D_badge.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }
}
