.phone {
  height: 72vh;

  position: relative;

  canvas {
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  &__inner {
    display: flex;
    justify-content: center;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }

  .tap_to_start {
    width: 64%;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .tap_invite {
    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(12.7888112vh);
    height: calc(12.7888112vh);

    position: absolute;
    bottom: 15.3846154%;
    z-index: 3;

    transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer;

    .circle {
      width: 100%;
      height: 100%;

      border-radius: 50%;
      background-color: #db1d51;
      position: absolute;
      opacity: 0;
      animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
      z-index: 2;
    }

    .one {
      animation-delay: -3s;
    }
    .two {
      animation-delay: -2s;
    }
    .three {
      animation-delay: -1s;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .white_dot {
      z-index: 4;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: white;

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      transform: translate(-50%, -50%);
    }

    @keyframes scaleIn {
      from {
        transform: scale(0.5, 0.5);
        opacity: 0.5;
      }
      to {
        transform: scale(2, 2);
        opacity: 0;
      }
    }
  }

  .enjoy {
    position: absolute;
    top: 50%;
    transform: translsateY(-50%);

    opacity: 0;
    transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }

  .character {
    width: 23.6643357vh;

    position: absolute;
    bottom: 3.6363636%;

    opacity: 0;
    transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }
}
