.header {
  width: 100%;
  height: 64px;
  background-color: white;
  box-shadow: 0 4px 62px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  z-index: 99;
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);

  @media (min-width: 1024px) {
    height: 82px;
  }

  .header_content {
    width: 88.8888888888%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu_logo {
      font-size: 0;

      img {
        height: 24px;

        @media (min-width: 1024px) {
          height: auto;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      margin-left: 130px;
      font-size: 15px;
      //background-color: tomato;

      @media only screen and (max-width: 768px) {
        margin-left: 90px;
      }

      @media only screen and (max-width: 652px) {
        margin-left: 70px;
      }

      @media only screen and (max-width: 682px) {
        display: none;
      }

      a {
        color: black;
        text-decoration: none;
        cursor: pointer;
        border-radius: 56px;
        padding: 7px 15px 6px 16px;
        margin: -7px -15px -6px -16px;
        transition: background-color 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1);
      }

      li {
        display: inline-block;
        background-color: transparent;
        padding: 7px 15px 6px 16px;
        border-radius: 56px;

        a:hover:not(.active) {
          background-color: #db1d51;
          color: white;
        }
      }

      li:not(:last-child) {
        margin-right: 60px;

        @media only screen and (max-width: 863px) {
          margin-right: 40px;
        }

        @media only screen and (max-width: 815px) {
          margin-right: 35px;
        }

        @media only screen and (max-width: 803px) {
          margin-right: 30px;
        }

        @media only screen and (max-width: 790px) {
          margin-right: 25px;
        }

        @media only screen and (max-width: 780px) {
          margin-right: 10px;
        }
      }

      .active {
        background-color: #db1d51;
        color: white;
        border-radius: 56px;
        padding: 7px 15px 6px 16px;
        margin: -7px -15px -6px -16px; // negative margins used to remove padding from initial class
      }
    }

    .appstore_badge {
      background: #000;
      padding: 2px 12px;
      border-radius: 56px;
      display: flex;

      img {
        height: 28px;

        @media (min-width: 1024px) {
          height: auto;
        }
      }
    }
  }

  &--hidden {
    transform: translateY(-100%);
  }

  &--appear,
  &--enter {
    transition: none;
    transform: translateY(-100%);

    &-active {
      transition: transform 1s 1s cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(0);
    }
  }
  &--exit {
    transition: none;
    transform: translateY(0);

    &-active {
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(-100%);
    }
  }
}
