@font-face {
  font-family: "Harbour";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Harbour-Medium/Harbour\ W00\ Medium.eot"); /* IE9 Compat Modes */
  src: local("Open Sans"), local("OpenSans"),
    url("../fonts/Harbour-Medium/Harbour\ W00\ Medium.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/Harbour-Medium/Harbour\ W00\ Medium.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/Harbour-Medium/Harbour\ W00\ Medium.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/Harbour-Medium/Harbour\ W00\ Medium.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/Harbour-Medium/Harbour\ W00\ Medium.svg") format("svg"); /* Legacy iOS */
}

@import url("https://use.typekit.net/yeb4kiz.css");

body {
  font-family: roc-grotesk, "Arial", "Helvetica", sans-serif;
  font-size: 62.5%;
  background: white;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 82px;
}

@import "./components/loading";
@import "./components/header";
@import "./components/footer";
@import "./components/cookies-banner";
@import "./components/phone";
@import "./components/features";
@import "./components/slider";

hr {
  border-top: 0.5px solid #000000;
  position: absolute;
  left: 10%;
  right: 10%;

  @media only screen and (max-width: 414px) {
    right: 5%;
    left: 5%;
  }
}

.credits {
  margin-left: 20px;
  font-size: 18px;
  margin-bottom: 64px;

  @media (min-width: 1024px) {
    margin-left: 5.5555555556%;
  }

  &__title {
    font-size: 20px;
    line-height: 101.13%;
    font-weight: 500;
    margin-bottom: 40px;

    @media (min-width: 1024px) {
      font-size: 24px;
      margin-bottom: 64px;
    }
  }

  &__details {
    display: grid;
    grid-row-gap: 32px;

    @media (min-width: 1024px) {
      grid-template-columns: 33% 33% 33%;
    }

    span {
      font-size: 16px;

      @media (min-width: 1024px) {
        font-size: 18px;
      }

      p {
        margin: 0;
      }

      a {
        display: inline;
        color: black;
        text-decoration: none;
        font-size: 16px;
        font-style: normal;

        @media (min-width: 1024px) {
          font-size: 18px;
        }

        &:hover {
          color: #db1d51;
          border-bottom: 1px solid #db1d51;
        }

        &.interaction-disabled {
          &:hover {
            color: black;
            border-bottom: none;
          }
        }
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px !important;

      @media (min-width: 1024px) {
        font-size: 18px;
        margin-bottom: 16px !important;
      }
    }
  }
}

.partnership {
  position: relative;
  top: 70px;
  margin-left: 10%;
  margin-right: 10%;

  @media only screen and (max-width: 500px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  p {
    font-size: 24px;
    line-height: 101.13%;
    font-weight: 600;
    margin-bottom: 50px;
  }

  .scrollable {
    overflow: auto;

    .logos {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      overflow: auto;

      @media only screen and (max-width: 500px) {
        width: 200%;
      }

      a:not(:last-child) {
        margin-right: 94px;
        @media only screen and (max-width: 414px) {
          margin-right: 52px;
        }

        @media only screen and (max-width: 360px) {
          margin-right: 40px;
        }

        @media only screen and (max-width: 320px) {
          margin-right: 31px;
        }
      }

      a:hover {
        cursor: pointer;
      }
    }
  }

  .scrollable::-webkit-scrollbar {
    display: none;
  }

  .logos::-webkit-scrollbar {
    display: none;
  }
}

.download {
  padding: 40px 20px 0;
  background: black;
  margin-bottom: 64px;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 294px 0 188px;
    margin-bottom: 72px;
  }

  &__claim {
    max-width: 78.4%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 1024px) {
      max-width: none;
      margin-top: 120px;
      margin-bottom: 60px;
    }

    .appstore_badge {
      width: 36vw;
    }

    img {
      max-width: 100%;

      &.text {
        margin: 16px 0 32px;

        @media (min-width: 1024px) {
          margin-top: 24px;
          margin-bottom: 96px;
        }
      }
    }
  }

  &__device {
    margin: 72px 20px 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #c4c4c4;
    background-image: url("../images/device_bg_1.png"),
      linear-gradient(to right, RGB(219, 29, 81), RGB(219, 29, 81));
    background-blend-mode: darken;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: 1024px) {
      margin: 56px 0 0;
    }

    &__title {
      margin-top: 24px;
      margin-bottom: 96px;

      @media (min-width: 1024px) {
        margin: 28px 110px 204px;
      }
    }

    &__bosch {
      width: 144px;
      margin: 0 auto;
    }

    &__square {
      width: 96px;
      height: 96px;
      border-radius: 20px;
      transform: translateX(-20px);
      margin-bottom: 16px;

      @media (min-width: 1024px) {
        width: 134px;
        height: 134px;
        position: absolute;
        left: 0;
        bottom: 60px;
        transform: translateX(-50%);
      }
    }

    &__bosch-character {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 52%;
      transform: translate(40px, 50%);

      @media (min-width: 1024px) {
        width: auto;
        transform: translate(50%, 50%);
      }
    }
  }
}

.about {
  width: 100%;
  padding-bottom: 112px;
  margin-top: 291px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 76px;
    margin-top: 72px;
  }

  .about_text {
    height: 20%;
    margin-left: 10%;
    margin-top: 100px;
    margin-bottom: 72px;

    @media only screen and (max-width: 1000px) {
      text-align: center;
      margin-top: 72px;
      margin-left: auto;
    }

    @media only screen and (max-width: 768px) {
      height: auto;
      margin-bottom: 48px;
    }

    @media only screen and (max-width: 375px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    .title {
      margin-bottom: 25px;
    }

    .title2 {
      margin: 0;
      margin-bottom: 25px;

      @media only screen and (max-width: 414px) {
        margin-right: 8px;
        margin-left: 8px;
      }

      @media only screen and (max-width: 320px) {
        font-size: 35px;
      }
    }

    p {
      margin: 0;
      max-width: 800px;

      @media only screen and (max-width: 375px) {
        font-size: 16px;
      }
    }

    .p-mobile {
      display: none;
    }

    @media only screen and (max-width: 1000px) {
      .p-mobile {
        display: inline-block;
      }

      .p-desktop {
        display: none;
      }
    }

    .apostrophe {
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }
  }

  .pic_container {
    width: 100%;
    margin: 0 auto;
    padding: 0 10%;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
      padding: 0;
    }

    img {
      width: 100%;
    }
  }
}

.carousel_section {
  width: 100%;
  height: auto;
  z-index: 1;

  @media only screen and (max-width: 1024px) {
    height: 600px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
  }

  @media only screen and (max-width: 414px) {
    height: 350px;
  }

  .title {
    position: relative;
    left: 10%;

    @media only screen and (max-width: 768px) {
      left: 0;
    }
  }

  .carousel_container {
    width: 80%;
    //height: 800px;
    position: relative;
    margin: 20px auto;

    //background-color: aqua;
    //z-index: 5;

    // @media only screen and (min-width: 1050px) {
    //   width: 88%;
    // }

    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    .slick-slider {
      //background-color: gray;
      width: 100%;
      margin: 25px auto 0;
      // @media only screen and (max-width: 1024px) {
      //   width: 90%;
      // }

      .slick-track {
        font-size: 0;
      }

      .slide {
        width: 100%;
        height: 32.53373313vh;
        position: relative;
        outline: none;

        @media (min-width: 1024px) {
          height: 30.612244898vh;
        }

        &__filter {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: black;
          opacity: 0.2;
          z-index: 2;
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;

          &.slide__detail {
            z-index: 3;
            height: 128%;
            width: auto;
            object-fit: unset;

            @media (min-width: 1024px) {
              height: 196%;
            }

            &--1 {
              left: auto;
              transform: translate3d(12%, -16%, 0);
            }

            &--2 {
              top: 50%;
              bottom: auto;
              left: auto;
              transform: translate3d(-12%, -50%, 0);
            }

            &--3 {
              top: 50%;
              bottom: auto;
              right: auto;
              left: 50%;
              transform: translate3d(-62%, -50%, 0);
            }

            &--4 {
              top: 50%;
              bottom: auto;
              right: auto;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
            }

            &--5 {
              top: 50%;
              bottom: auto;
              right: auto;
              left: 50%;
              transform: translate3d(-70%, -50%, 0);
            }
          }
        }
      }
    }
    .slick-dots {
      position: relative;
      top: 108%;
      height: 30px;
      z-index: 2;
      display: block;
      width: 100%;
      padding: 0;
      margin: 16px 0 0;
      text-align: center;

      @media only screen and (max-width: 830px) {
        bottom: -100px;
      }

      @media only screen and (max-width: 430px) {
        bottom: -100px;
      }
    }
    .slick-dots li {
      position: relative;

      display: inline-block;

      width: 31px;
      height: 20px;
      margin: 0 5px;
      padding: 0;

      cursor: pointer;
    }

    .slick-dots li button {
      padding: 4px;
    }

    .slick-dots li button:before {
      content: "";
      display: inline-block;
      width: 31px;
      height: 2px;
      background: black;
      opacity: 0.25;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
    }

    .carousel_char_1 {
      position: absolute;
      width: 60%;
      height: 90%;
      left: 5%;
      bottom: -16%;
      max-height: 300px;
      background-image: url("../images/character_1.png");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 1;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 768px) {
        left: 8%;
        max-height: 160px;
      }
    }

    .carousel_char_2 {
      position: absolute;
      width: 70%;
      height: 63.7837837838%;
      left: 5%;
      bottom: -16%;
      max-height: 236px;
      background-image: url("../images/character_2.png");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 768px) {
        left: 10%;
        max-height: 118px;
      }
    }

    .carousel_char_3 {
      position: absolute;
      width: 70%;
      height: 40.54054054%;
      left: 5%;
      bottom: -16%;
      max-height: 118px;
      background-image: url("../images/character_3.png");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 768px) {
        left: 10%;
        max-height: 80px;
      }
    }

    .carousel_char_4 {
      position: absolute;
      width: 50%;
      height: 50%;
      left: 5%;
      bottom: -16%;
      max-height: 210px;
      background-image: url("../images/character_4.png");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 768px) {
        left: 10%;
        max-height: 160px;
      }
    }

    .carousel_char_5 {
      position: absolute;
      width: 50%;
      height: 97.2972973%;
      left: 5%;
      bottom: -16%;
      max-height: 360px;
      background-image: url("../images/character_5.png");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      opacity: 0;
      transition: opacity 1.5s ease-out;

      @media only screen and (max-width: 768px) {
        left: 10%;
        max-height: 160px;
      }
    }
  }
}

@import "./components/screens-first-section";
@import "./components/screens-first-mobile-only";

// screens second section //
.desktop_only {
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.mobile_only {
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
    //background-color: greenyellow;

    .container {
      width: 90%;
      height: 2800px;
      margin: 0 auto;
      //background-color: cadetblue;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .title {
        order: 1;
      }

      .title2 {
        order: 2;

        @media only screen and (max-width: 768px) {
          text-align: center;
          width: 276px;
        }
      }

      .device1 {
        width: 276px;
        height: 489px;
        position: relative;
        top: 1%;
        border-radius: 25px;
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          linear-gradient(
            to top,
            rgba(0, 0, 0, 0.4) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          url("../images/screens_blur_ellipse.svg"),
          url("../images/loading_background.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
        display: flex;
        justify-content: center;
        z-index: 2;
        order: 3;

        // fixed elements
        .navbar {
          width: 90%;
          position: absolute;
          top: 20px;
          z-index: 3;
        }

        .lower {
          width: 90%;
          position: absolute;
          bottom: 20px;
          z-index: 3;
        }

        // elements that will move on scroll
        .red_grid {
          width: 100%;
          height: 38%;
          position: absolute;
          bottom: 0px;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
          background-image: url("../images/red_grid.svg");
          background-size: cover;
          z-index: 1;
        }
      }

      p {
        max-width: 276px;
        text-align: center;
        margin-top: 24px;
      }

      .desc1 {
        order: 4;
      }

      .device2 {
        width: 276px;
        height: 489px;
        position: relative;
        top: 1%;
        border-radius: 25px;
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          linear-gradient(
            to top,
            rgba(0, 0, 0, 0.4) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          url("../images/screens_blur_ellipse.svg"),
          url("../images/background_variant.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
        display: flex;
        justify-content: center;
        z-index: 2;
        order: 5;

        // fixed elements
        .navbar {
          width: 90%;
          position: absolute;
          top: 20px;
          z-index: 3;
        }

        .lower {
          width: 90%;
          position: absolute;
          bottom: 20px;
          z-index: 3;
        }

        .three_chars {
          width: 90%;
          position: absolute;
          bottom: 125px;
        }

        .dots {
          width: 15%;
          position: absolute;
          bottom: 75px;
        }
      }

      .scrollable {
        overflow: auto;
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 70px;
        left: 0;
        //background-color: tomato;

        .dots {
          height: 50px;
          position: absolute;
          top: 0;
          left: 37%;
          display: flex;
          justify-content: space-between;
          z-index: 2;
          //background-color: aqua;
        }
      }

      .scrollable::-webkit-scrollbar {
        display: none;
      }

      .desc2 {
        order: 6;
      }

      .device3 {
        width: 276px;
        height: 489px;
        position: relative;
        top: 1%;
        border-radius: 25px;
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          linear-gradient(
            to top,
            rgba(0, 0, 0, 0.4) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          url("../images/screens_blur_ellipse.svg"),
          url("../images/loading_background.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
        display: flex;
        justify-content: center;
        z-index: 2;
        order: 7;

        // fixed elements
        .navbar {
          width: 90%;
          position: absolute;
          top: 20px;
          z-index: 3;
        }

        .lower {
          width: 90%;
          position: absolute;
          bottom: 20px;
          z-index: 3;
        }

        .character1 {
          width: 50%;
          position: absolute;
          bottom: 135px;
        }

        // .dots {
        //   width: 15%;
        //   position: absolute;
        //   bottom: 75px;
        // }
      }

      .scrollable2 {
        overflow: auto;
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 70px;
        left: 0;
        //background-color: tomato;

        .dots2 {
          height: 50px;
          position: absolute;
          top: 0;
          left: 37%;
          display: flex;
          justify-content: space-between;
          z-index: 2;
          //background-color: aqua;
        }
      }

      .scrollable2::-webkit-scrollbar {
        display: none;
      }

      .desc3 {
        order: 8;
      }

      .device4 {
        width: 276px;
        height: 489px;
        position: relative;
        top: 1%;
        border-radius: 25px;
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          linear-gradient(
            to top,
            rgba(0, 0, 0, 0.4) 5.87%,
            rgba(0, 0, 0, 0) 100%
          ),
          url("../images/screens_blur_ellipse.svg"),
          url("../images/loading_background.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.28);
        display: flex;
        justify-content: center;
        z-index: 2;
        order: 9;

        .cancel_button {
          width: 8%;
          position: absolute;
          top: 20px;
          left: 20px;
          order: 1;
        }

        .character2 {
          width: 50%;
          position: absolute;
          bottom: 100px;
          order: 2;
        }

        .share_buttons {
          width: 90%;
          position: absolute;
          bottom: 20px;
          order: 3;
        }
      }
      .desc4 {
        order: 10;
      }
    }
  }
}
// end //
