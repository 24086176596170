body {
  &.loading-component-body {
    width: 100%;
    //background-color: aqua;
    height: 100vh; // set height based on viewport size
    box-sizing: border-box; // prevent scrolling
    padding-top: 0;
  }
}

.loading {
  display: block;

  width: 100%;
  height: 100vh;

  background-image: url("../../images/loading_background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  text-align: center;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  .title {
    display: block;
    width: 200px;
    height: 90px;
    margin: auto;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .subtitle {
      margin-top: 30px;
    }
  }

  .opacity_layer {
    height: 100%;

    background-color: #db1d51;
    mix-blend-mode: darken;

    // we avoind transition on transform for glitches with mix-blend-mode
    /*transition: height 1.6s cubic-bezier(0.86, 0, 0.07, 1);
    will-change: height;*/
  }

  &__progress {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);

    font-size: 18px;
    line-height: 1.22em;
    text-align: center;
    color: white;
  }

  &--exit {
    .title {
      opacity: 1;
    }

    &-active {
      .title {
        transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
        opacity: 0;
      }
    }
  }
}
