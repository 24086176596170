footer {
  background-color: #db1d51;
  width: 100%;
  bottom: 0;
  padding: 32px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding: 50px 80px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  span,
  a,
  p {
    font-size: 14px;
    color: white;
    font-weight: normal;

    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }

  p {
    margin: 0;
    color: white;
  }

  .footer__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    img {
      height: 16px;
      margin-bottom: 4rem;

      @media (min-width: 1024px) {
        height: 24px;
      }
    }
  }

  .footer__actions {
    margin-top: 4rem;
  }
}
